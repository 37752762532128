import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Accordion from "./../../components/common/products/accordion";
import ProductNav from "../../components/productNav";
import whiteBg from "./whiteBackground.jpg";
import SASS from "./SAASDataprocessing.png";
import Layout from "../../components/layout/layout";
import ProductFeatures from "../../components/common/products/productFeatures";
import ProductFeatures2 from "../../components/common/products/productFeatures2";
import ProductHeadline from "../../components/common/products/productHeadline";
import ProductHeading from "../../components/common/products/productHeading";
import formatTitle from "../../components/common/formatTitle";
import HeadingWithBg from "../../components/common/products/headingProductWithBg";
import ImageComparisonSlider from "../../components/comparisonSlider/imageComparisonSlider";
import Illustration2 from "./illustration_2.png";
import Securite from "./securite.jpg";
import PhotoAccordion from "./photo_accordion.jpg";
import Example1 from "./examples1.jpg";
import Example2 from "./examples2.jpg";
import Example3 from "./examples3.jpg";
import WithBoxes from "./with_boxes.png";
import WithoutBoxes from "./withtout_boxes.png";
import CountingDashboard from "./countingDashboard.png";

import PictoF11 from "./high_data.svg";
import PictoF12 from "./low_intrusive.svg";
import PictoF13 from "./easy_access_solution.svg";
import PictoF14 from "./considerable_time.svg";

import PictoF21 from "./vehicle_counting.svg";
import PictoF22 from "./classification.svg";
import PictoF23 from "./segmentation.svg";
import PictoF24 from "./open_system.svg";

const useStyles = makeStyles((theme) => ({
  row1: {
    padding: theme.layout.paddingRegular,
    backgroundColor: theme.palette.background.default,
  },
  noPaddingBottom: {
    paddingBottom: 30,
  },
  row1Mobile: {
    padding: theme.layout.paddingRegularMobile,
    backgroundColor: theme.palette.background.default,
  },
  withBg: {
    backgroundColor: theme.palette.background.medium,
  },
  products: { padding: theme.layout.paddingRegular },
  productsMobile: { padding: theme.layout.paddingRegularMobile },
  button: {
    backgroundColor: theme.palette.primary.light,
    color: "#FFF",
    marginTop: "2em",
  },
  target1Btn: {
    backgroundColor: "#FFF",
    color: theme.palette.primary.light,
    padding: "1em",
    minWidth: "150px",
  },
  centered: {
    textAlign: "center",
  },
  leftAligned: {
    textAlign: "left",
  },
  wBg: {
    backgroundImage: `url(${whiteBg})`,
    backgroundSize: "auto 100px",
    backgroundRepeat: "repeat-x",
  },
  enLight: {
    color: theme.palette.primary.light,
  },
  imgRnded: {
    borderRadius: 10,
  },
  dashboard: {
    boxShadow: "6px 15px 20px 8px #e8e7e7",
  },
  morePadded: {
    paddingBottom: "10%",
  },
  illustration: {
    marginBottom: theme.spacing(4),
  },
}));

const docNames = [
  "FR_Counting x Lima Expresa",
  "FR_Counting x DIRMED",
  "FR_Counting x Datacity",
];

export default function Counting({ data, pageContext: { slug, langKey } }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const datasParsed = [
    "accordion",
    "features1",
    "features2",
    "features3",
    "punchlines",
  ].reduce(
    (m, k) => ({
      ...m,
      [k]: data.allCountingJson.nodes.find((n) => n[k] !== null)[k][langKey],
    }),
    {}
  );

  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item>
        <Grid container direction="column" alignItems="stretch">
          <HeadingWithBg
            title={datasParsed["punchlines"][0]["title"]}
            content={datasParsed["punchlines"][0]["content"]}
            imgAlt={datasParsed["punchlines"][0]["alt"]}
            src={CountingDashboard}
            langKey={langKey}
            uri={`/${langKey}/contact?object=demo_counting`}
          />
          <ProductFeatures
            features={datasParsed["features1"]}
            iconOnSameRow={false}
            singleLineMode={false}
            icons={[
              { img: PictoF11, alt: "illustration1" },
              { img: PictoF12, alt: "illustration1" },
              { img: PictoF13, alt: "illustration1" },
              { img: PictoF14, alt: "illustration1" },
            ]}
          />
          <ProductHeadline
            title={datasParsed["punchlines"][1]["title"]}
            content={datasParsed["punchlines"][1]["content"]}
            more={datasParsed["punchlines"][1]["more"]}
            buttonParams={{
              label: "Nous contacter",
              uri: `/${langKey}/contact`,
            }}
          />
          <Grid
            item
            className={clsx(classes.centered, {
              [classes.noPaddingBottom]: matches,
              [classes.row1]: matches,
              [classes.withBg]: matches,
              [classes.row1Mobile]: !matches,
            })}
            container
            direction="column"
            spacing={5}
          >
            <Grid item className={classes.illustration}>
              <img
                src={Illustration2}
                alt="Counting+"
                width={!matches ? "100%" : "auto"}
                height="auto"
                className={classes.dashboard}
              />
            </Grid>
            <ProductFeatures
              features={datasParsed["features2"]}
              iconOnSameRow={!matches}
              singleLineMode={false}
              icons={[
                { img: PictoF21, alt: "illustration1" },
                { img: PictoF22, alt: "illustration1" },
                { img: PictoF23, alt: "illustration1" },
                { img: PictoF24, alt: "illustration1" },
              ]}
              noPadding
              withBg
            />
          </Grid>
          <Grid
            item
            className={clsx({
              [classes.row1]: matches,
              [classes.row1Mobile]: !matches,
            })}
          >
            <Grid container direction="row" justify="space-between" spacing={4}>
              <Grid item sm={6} xs={12}>
                <img
                  src={PhotoAccordion}
                  width="100%"
                  height="auto"
                  alt="roadshare"
                  className={classes.imgRnded}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Accordion expandedDark items={datasParsed["accordion"]} />
              </Grid>
            </Grid>
          </Grid>

          {matches && (
            <Grid item className={clsx(classes.morePadded, classes.row1)}>
              <Grid container direction="column" spacing={4}>
                <Grid item xs={4} md={12} className={classes.centered}>
                  <Typography variant="h1">
                    <span className={classes.enLight}>Démo</span> en images
                  </Typography>
                </Grid>
                <Grid item className={classes.centered}>
                  <div style={{ width: 1024, height: 298, margin: "auto" }}>
                    <ImageComparisonSlider
                      image1={WithoutBoxes}
                      image2={WithBoxes}
                      sliderColor={theme.palette.primary.light}
                      sliderWidth={5}
                      showRightHandle
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          <ProductHeading
            title={formatTitle(datasParsed["punchlines"][2]["title"])}
            content={datasParsed["punchlines"][2]["content"]}
            imgAlt={datasParsed["punchlines"][2]["alt"]}
            src={SASS}
            imageSizeOnMobile={12}
            noButtonOnMobile
            illustrationInverted
            buttonParams={{
              label: "Demander une demo",
              uri: `/${langKey}/contact?object=demo_counting`,
            }}
            tag="Études de mobilité"
          />
          <ProductHeading
            title={formatTitle(datasParsed["punchlines"][3]["title"])}
            content={datasParsed["punchlines"][3]["content"]}
            imgAlt={datasParsed["punchlines"][3]["alt"]}
            src={Securite}
            imageSizeOnMobile={12}
            reverse
            noButtonOnMobile
            buttonParams={{
              label: "Nous contacter",
              uri: `/${langKey}/contact`,
            }}
            tag="Gestion du trafic"
          />
          <ProductFeatures2
            features={[Example1, Example2, Example3].map((image, idx) => ({
              image,
              ...datasParsed["features3"][idx],
              docName: docNames[idx],
            }))}
            langKey={langKey}
          />
          <Grid
            item
            className={clsx({
              [classes.products]: matches,
              [classes.productsMobile]: !matches,
            })}
            xs={12}
            container
            direction="column"
            spacing={matches ? 4 : 0}
            alignItems="stretch"
          >
            <Grid item container direction="row" justify="center">
              <Grid
                item
                xs={12}
                className={clsx({
                  [classes.centered]: matches,
                  [classes.leftAligned]: !matches,
                })}
              >
                <Typography variant="h1">
                  Découvrez nos{" "}
                  <span className={classes.enLight}>produits</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" justify="center" spacing={2}>
              <Grid item xs={10}>
                <ProductNav
                  langKey={langKey}
                  toHide={[slug.split("/")[2].toLowerCase() + "+"]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export const query = graphql`
  query Counting {
    allCountingJson {
      nodes {
        accordion {
          fr {
            content
            title
          }
        }
        punchlines {
          fr {
            content
            title
            more
            alt
          }
        }
        features3 {
          fr {
            content
            title
            alt
          }
        }
        features2 {
          fr {
            content
            title
          }
        }
        features1 {
          fr {
            content
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
